import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import integrationRequest from "requests/integrationRequest";
import Loading from "components/Loading";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
  UncontrolledButtonDropdown,
} from "reactstrap";

import "./style.css";

const Integrations = () => {
  const navigate = useNavigate();
  const [integrations, setIntegrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    integrationRequest
      .getAll()
      .then((resp) => {
        setIntegrations(resp);
        setLoading(false);
      })
      .catch((err) => console.error(err));
  }, []);

  const integrationDetailsHandle = (integration) => {
    navigate(`/integrations/access?integration_id=${integration.id}`, {
      state: { integration },
    });
  };

  const eventDetailsHandle = (eventId) => {
    // navigate(`/integrations/events?integration_id=${integration.id}`, {
    //   state: { integration },
    // });
    navigate({
      pathname: "/integrations/events",
      search: `?integration_id=${eventId}`,
    })
  };

  const customerDetailsHandle = (eventId) => {
    navigate({
      pathname: "/integrations/customers",
      search: `?integration_id=${eventId}`,
    });
  };

  return (
    <div className="integrations-container">
      <div className="card-title-container">
        <h3 className="card-title">Integrações</h3>
      </div>
      <div className="table-container">
        {integrations?.length < 1 ? (
          <span className="nullable-table">
            {loading ? (
              <Loading message={"carregando integrações..."} />
            ) : (
              <h3 className="nullable-table">Nenhuma Integração Encontrada!</h3>
            )}
          </span>
        ) : (
          <table>
            <thead>
              <tr>
                <th className="table-head">Nome</th>
                <th
                  className="table-head"
                  colSpan="1"
                  style={{ textAlign: "center" }}
                >
                  Opções
                </th>
              </tr>
            </thead>
            <tbody>
              {integrations?.map((integration) => {
                return (
                  <tr key={integration?.id}>
                    <td className="main-col">{integration?.name} </td>
                    <td>
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret> </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              integrationDetailsHandle(integration);
                            }}
                          >
                            Acessos
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              eventDetailsHandle(integration?.id);
                            }}
                          >
                            Eventos
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Integrations;
