import "./index.css";

import toast from "react-hot-toast";

import { useEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";

import dateHelper from "helpers/dateHelper";
import eventsRequest from "requests/eventsRequest";
import integrationRequest from "requests/integrationRequest";

const Events = () => {
  const [urlParameters] = useSearchParams();

  const [events, setEvents] = useState([]);
  const [brokerEvents, setBrokerEvents] = useState([]);
  const [selectedModal, setSelectedModal] = useState("maritimo");
  const [transvoyantDescriptionEventId, setTransvoyantDescriptionEventId] =
    useState();
  const [integration, setIntegration] = useState({ name: ""})

  useEffect(() => {
    const integrationId = urlParameters.get("integration_id");

    toast
      .promise(eventsRequest.get(integrationId), {
        loading: "Carregando eventos da integração",
        success: "Eventos carregados",
        error: "Não foi possível carregar os eventos",
      })
      .then((response) => setEvents(response));

    eventsRequest
      .getBrokerEvents()
      .then((response) => setBrokerEvents(response));

    integrationRequest
    .get(integrationId)
    .then((response) => setIntegration(response))

  }, []);

  const handleUpdateEvent = (transvoyantEvent, e) => {
    const brokerEventId = e.target.value == "" ? null : e.target.value;

    const requestBody = {
      id: transvoyantEvent?.id,
      broker_event_id: brokerEventId,
    };

    toast
      .promise(eventsRequest.updateBrokerEvent(requestBody), {
        loading: "Atualizando evento",
        success: "Evento atualizado com sucesso",
        error: "Não foi possível atualizar o evento",
      })
      .then(() => {
        transvoyantEvent.broker_event_id = brokerEventId;
        setEvents([...events]);
      });
  };

  const handleUpdateEventDescription = (transvoyantEvent, inputValue) => {
    if (transvoyantDescriptionEventId !== transvoyantEvent?.id) {
      setTransvoyantDescriptionEventId(transvoyantEvent?.id);
    }

    transvoyantEvent.event_name = inputValue;
    transvoyantEvent._changed = true;
    setEvents([...events]);
  };

  const handleSaveEventDescription = (e, transvoyantEvent) => {
    e.preventDefault();

    const requestBody = {
      id: transvoyantEvent?.id,
      event_description: transvoyantEvent?.event_name,
    };

    toast
      .promise(eventsRequest.updateEventDescription(requestBody), {
        loading: "Atualizando descrição do evento",
        success: "Descrição atualizada",
        error: "Não foi possível atualizar a descrição do evento",
      })
      .then(() => {
        transvoyantEvent._changed = false;
        setEvents([...events]);
      });
  };

  const handleAddInfo = () => {
    let data = {
      modal: selectedModal,
      integration_id: urlParameters.get("integration_id"),
    };

    toast
      .promise(eventsRequest.addInfoEvent(data), {
        loading: "Adicionando evento",
        success: "Sucesso",
        error: "Não foi possível adicionar novo evento",
      })
      .then((res) => {
        setEvents([...events, res]);
      });
  };

  const handleDeleteInfo = (event) => {
    let data = {
      id: event.id,
    };

    toast
      .promise(eventsRequest.deleteInfoEvent(data), {
        loading: "Excluindo evento INFO",
        success: "Sucesso",
        error: "Não foi possível excluir novo evento",
      })
      .then((res) => {
        event._deleted = true;
        setEvents([...events]);
      });
  };

  return (
    <div className="container">
      <Card>
        <CardHeader>Eventos {integration?.name}</CardHeader>
        <CardBody>
          <Nav tabs>
            <NavItem
              className={`${selectedModal === "maritimo" ? "active-tab" : ""}`}
              onClick={() => setSelectedModal("maritimo")}
            >
              Marítimo
            </NavItem>
            <NavItem
              className={`${selectedModal === "aereo" ? "active-tab" : ""}`}
              onClick={() => setSelectedModal("aereo")}
            >
              Aéreo
            </NavItem>
            <NavItem
              className={`${
                selectedModal === "rodoviario" ? "active-tab" : ""
              }`}
              onClick={() => setSelectedModal("rodoviario")}
            >
              Rodoviário
            </NavItem>
          </Nav>
          <TabContent>
            <TabPane>
              <Row>
                <Col sm="12">
                  <div
                    className="event-container"
                    key="header"
                    style={{ fontWeight: "bold" }}
                  >
                    <Label>Código</Label>
                    <span>
                      <Label>Descrição</Label>
                    </span>
                    <div>
                      <Label>Evento</Label>
                    </div>
                    <div> </div>
                  </div>
                  {events?.map((event) => {
                    if (selectedModal === event?.modal?.toLowerCase()) {
                      return (
                        <div
                          className="event-container"
                          key={event?.id}
                          style={{ opacity: event._deleted ? 0.5 : 1 }}
                        >
                          <Label>{event?.event_code}</Label>
                          <form
                            className="event-description"
                            onSubmit={(e) =>
                              handleSaveEventDescription(e, event)
                            }
                          >
                            <span>
                              {event?.event_code === "INFO" && (
                                <Input
                                  disabled={event._deleted}
                                  type="text"
                                  placeholder={`Descrição evento ${integration.name}`}
                                  onChange={(e) =>
                                    handleUpdateEventDescription(
                                      event,
                                      e.target.value
                                    )
                                  }
                                  value={
                                    event?.event_name !== event?.event_code
                                      ? event?.event_name
                                      : ""
                                  }
                                  style={{ cursor: "text" }}
                                />
                              )}
                            </span>
                            {event?._changed === true && (
                              <Button type="submit" size="sm" color="primary">
                                Editar
                              </Button>
                            )}
                          </form>
                          <div>
                            <Row>
                              <Col sm="9">
                                <Input
                                  value={
                                    event?.broker_event_id
                                      ? event?.broker_event_id
                                      : ""
                                  }
                                  type="select"
                                  disabled={event._deleted}
                                  onChange={(e) => handleUpdateEvent(event, e)}
                                >
                                  <option value=""> Nenhum</option>
                                  {brokerEvents?.map((brokerEvent) => (
                                    <option
                                      key={brokerEvent?.IDEVENTO}
                                      value={brokerEvent?.IDEVENTO}
                                    >
                                      {brokerEvent?.NMEVENTO} - (
                                      {brokerEvent?.CDEVENTO})
                                    </option>
                                  ))}
                                </Input>
                              </Col>
                              <Col sm="2">
                                {event?.event_code == "INFO" &&
                                  !event?._deleted && (
                                    <Col sm="2">
                                      <Button
                                        onClick={(e) => handleDeleteInfo(event)}
                                        color="danger"
                                        size="sm"
                                      >
                                        Excluir
                                      </Button>
                                    </Col>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    }
                  })}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <Col style={{ padding: "10px" }}>
            <Button size="sm" color="primary" onClick={handleAddInfo}>
              Adicionar Evento Informativo
            </Button>
          </Col>
        </CardBody>
      </Card>
    </div>
  );
};

export default Events;
